<template>
  <div style="max-width: 960px" class="max-w-fit">
    <div v-if="showStartTrial" class="p-20 text-center">
      <h3 class="text-upgrade text-4xl">
        {{ $t('Start your trial now') }}
      </h3>
      <p>
        {{
          $t(
            'Start your trial to experience the full features of Transkribus scholar. After your trial phase, you can keep using Transkribus for free or upgrade to a Scholar plan. Your trial will start right now and will end on'
          )
        }}
      </p>
      <p class="font-bold text-lg">
        <BaseDate :value="getExpirationDate()" />
      </p>
      <div class="flex gap-4 mt-8">
        <BaseButton
          class="grow"
          :type="'secondary'"
          :label="$t('Cancel')"
          @click="$emit('close')"
        ></BaseButton>
        <BaseButton
          class="grow"
          :type="'upgrade'"
          :label="$t('Start trial')"
          @click="checkTrial()"
        ></BaseButton>
      </div>
    </div>
    <div v-else>
      <div class="flex justify-between">
        <div class="w-1/2 px-8 py-8">
          <div>
            <p class="mb-0 text-sm">{{ subheading }}</p>

            <h3
              class="text-upgrade text-3xl leading-9 pt-4"
              v-html="heading"
            ></h3>
            <p class="py-5 text-sm">{{ description }}</p>
            <div>
              <div
                class="text-upgrade"
                v-for="(feature, index) in features"
                :key="index"
              >
                <i class="mdi mdi-arrow-up-bold-circle"></i> {{ feature }}
              </div>
            </div>
          </div>
          <!-- class="grid grid-cols-2 gap-4 pt-auto  content-end " -->
          <div class="flex-grow">
            <div class="flex gap-4 pt-8 pb-0 content-end">
              <BaseButton
                v-if="isSites"
                class="grow"
                :type="'upgrade'"
                :label="$t('See options')"
                @click="openUrl('https://transkribus.org/plans/sites')"
              ></BaseButton>
              <!-- Start trial if not used else Upgrade now -->
              <BaseButton
                v-if="!isSites"
                class="grow"
                :type="'upgrade'"
                :label="
                  subscription?.usedTrial
                    ? $t('Upgrade now')
                    : $t('Start trial')
                "
                @click="trialOrUprade()"
              ></BaseButton>
              <BaseButton
                class="grow"
                :icon="'open-in-new'"
                iconRight
                :type="'secondary'"
                :label="$t('Learn more')"
                @click="openUrl('https://transkribus.org/plans')"
              ></BaseButton>
            </div>
          </div>
        </div>
        <div class="w-1/2 px-8 py-8 bg-upgrade-50 flex items-center">
          <img :src="`/images/${image}.svg`" style="max-height: 350px" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Premium',
  setup() {
    const subscriptionApi = useSubscriptions()
    const { keycloak, userProfile } = useKeycloak()
    return {
      subscriptionApi,
      keycloak,
      userProfile,
    }
  },
  props: {
    heading: {
      type: String,
      default: 'Heading',
    },
    subheading: {
      type: String,
      default: 'Sub Heading',
    },
    description: {
      type: String,
      default:
        'Description text which shortly explains the current plan and the advantages ',
    },
    features: {
      type: Array,
      default: () => ['Premium feature', 'Premium feature', 'Premium feature'],
    },
    image: {
      type: String,
      default: 'premium-placeholder',
    },
    isSites: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    userId() {
      return this.userProfile?.UserId
    },
  },
  data() {
    return {
      isLoading: false,
      subscription: null,
      showStartTrial: false,
    }
  },
  async mounted() {
    await this.fetchSubscription()
  },
  methods: {
    trialOrUprade() {
      if (this.subscription?.usedTrial) {
        this.$emit('upgrade')
        this.openUrl('https://transkribus.org/scholar-upgrade')
      } else {
        this.showStartTrial = true
      }
    },
    async checkTrial() {
      this.$emit('trialStart')
      if (!this.$features.posthogTesting) {
        this.isLoading = true
        let response
        try {
          response = await this.subscriptionApi.startTrial({
            userId: this.userId,
          })
          // Force a new token after starting the trial
          await this.getNewKeycloakToken()
          this.$bus.emit('notification', {
            message: this.$t('Trial started successfully'),
            type: 'success',
          })
          this.$bus.emit('close-modal')
          reloadNuxtApp()
        } catch (err) {
          this.$bus.emit('notification', {
            message: this.$t('Trial start failed'),
            type: 'error',
          })
          this.$sentry.captureException(err)
        }
        this.isLoading = false
      } else {
        this.$bus.emit('close-modal')
      }
    },
    getExpirationDate() {
      const date = new Date()
      date.setDate(date.getDate() + 30)
      return date.toDateString()
    },
    async getNewKeycloakToken() {
      try {
        await this.keycloak.loadUserProfile()
        await this.keycloak.updateToken(-1)
      } catch (error) {
        this.$sentry.captureException(error)
      }
    },
    async fetchSubscription() {
      const { plan, organization, subscription } =
        await this.subscriptionApi.getSubscription({ userId: this.userId })
      if (subscription) {
        this.subscription = subscription
      }
    },

    openUrl(url) {
      window.open(url, '_blank')
    },
  },
}
</script>
<style>
.modal {
  --tw-bg-opacity: 0.56;
}
.modal-box {
  border-radius: 4px;
}
</style>
